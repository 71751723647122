import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import backGroundImage from '../../assets/images/bg-image-user-profile.svg';
import profilePicture from '../../assets/images/profile-picture.png';
import { useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  SelectChangeEvent,
  Box,
  Drawer,
  Avatar,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Pagination,
  Select,
  MenuItem,
  SxProps,
  Theme,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
} from '@mui/material';
import { useLocalStorage } from '../../context/LocalStorageContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LogoutComponent from '../common/LogoutComponent';

type AssessmentLibraryPageProps = {
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
  assessments: any[];
  onSelect: (assessmentId: string, topicsIds: number[]) => void;
  currentPage: number;
  itemsPerPage: 3 | 6 | 9 | 12;
  totalItems: number;
  onPageChange: (page: number) => void;
  onItemsPerPageChange: (pageSize: 3 | 6 | 9 | 12) => void;
  sortPath: 'scheduledDate' | 'dueDate';
  sortDirection: 'asc' | 'desc';
  onSortPathChange: (newSortPath: 'scheduledDate' | 'dueDate') => void;
  onSortDirectionChange: (newSortDirection: 'asc' | 'desc') => void;
  selectedGrade: number;
  onGradeChange: (newGrade: number) => void;
  onRetry: () => void;
};

const mainContainerStyles: SxProps<Theme> = {
  display: 'flex',
  height: '100vh',
  backgroundImage: `url(${backGroundImage})`,
};

const drawerStyles = (isMobile: boolean, drawerWidth: number): SxProps<Theme> => ({
  width: isMobile ? '90%' : drawerWidth,
});

const drawerContentStyles = (isMobile: boolean, drawerWidth: number): SxProps<Theme> => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: isMobile ? '100%' : drawerWidth,
  marginTop: '80px',
});

const welcomeMessageStyles: SxProps<Theme> = {
  textAlign: 'center',
  color: '#666',
  padding: '16px',
  marginTop: '16px',
  fontSize: '0.9rem',
  backgroundColor: 'transparent',
};

const avatarStyles: SxProps<Theme> = {
  width: 120,
  height: 120,
  mt: 2,
  mb: 2,
};

const listStyles = (drawerWidth: number): SxProps<Theme> => ({
  width: drawerWidth,
  marginTop: '20px',
  '& .MuiListItemButton-root': {
    marginBottom: '8px',
  },
});

const listItemButtonStyles = (selected: boolean): SxProps<Theme> => ({
  borderRadius: '8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#46B8B0',
    color: 'white',
    transform: 'scale(1.02)',
    boxShadow: '0 4px 8px rgba(70, 184, 176, 0.2)',
  },
  backgroundColor: selected ? '#46B8B0' : 'transparent',
  color: selected ? 'white' : 'inherit',
  padding: '12px 16px',
});

const closeButtonStyles: SxProps<Theme> = {
  position: 'absolute',
  top: 12,
  right: 0,
};

const appBarStyles: SxProps<Theme> = {
  backgroundColor: '#46B8B0',
  position: 'sticky',
  top: 0,
  zIndex: 3,
};

const toolbarStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
};

const loadingErrorEmptyStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  gap: 2,
};

const assessmentButtonStyles: SxProps<Theme> = {
  backgroundColor: '#46B8B0',
  color: '#fff',
  textTransform: 'none',
  marginTop: 2,
  borderRadius: '8px',
  '&:hover': { backgroundColor: '#38a69f' },
};

const contentContainerStyles = (isMobile: boolean, drawerOpen: boolean, drawerWidth: number): SxProps<Theme> => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginLeft: isMobile ? '0' : drawerOpen ? `0` : `-${drawerWidth}px`,
  transition: 'margin 0.3s, width 0.3s',
});

const headerTypographyStyles: SxProps<Theme> = {
  flexGrow: 1,
  fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
};

const filterSortContainerStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #eee',
  position: 'sticky',
  top: '64px',
  zIndex: 2,
};

const assessmentHeaderStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '16px',
  backgroundColor: 'transparent',
  textAlign: 'center',
};

const gridContainerStyles: SxProps<Theme> = {
  flexGrow: 1,
  overflowY: 'auto',
  padding: '16px',
};

const gridItemStyles: SxProps<Theme> = {
  justifyContent: 'space-evenly',
};

const cardStyles: SxProps<Theme> = {
  height: '320px',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 8px 16px rgba(70, 184, 176, 0.2)',
  },
};

const cardMediaStyles: SxProps<Theme> = {
  height: '120px',
  transition: 'all 0.3s ease',
};

const cardContentStyles: SxProps<Theme> = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const paginationContainerStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  borderTop: '1px solid #eee',
  backgroundColor: '#fff',
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
};

const selectStyles: SxProps<Theme> = {
  minWidth: 120,
  color: '#46B8B0',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#46B8B0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#46B8B0',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#46B8B0',
  },
};

const paginationStyles: SxProps<Theme> = {
  '& .MuiPaginationItem-root': {
    color: '#46B8B0',
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    backgroundColor: '#46B8B0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#38a69f',
    },
  },
  '& .MuiPaginationItem-page:hover': {
    backgroundColor: '#e0f2f1',
  },
};

const completedOverlayStyles: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  zIndex: 1,
};

const AssessmentLibrary: React.FC<AssessmentLibraryPageProps> = ({
  isLoading,
  hasError,
  isEmpty,
  assessments,
  onSelect,
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onItemsPerPageChange,
  sortPath,
  sortDirection,
  onSortPathChange,
  onSortDirectionChange,
  selectedGrade,
  onGradeChange,
  onRetry, // Destructure onRetry from props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const { state } = useLocalStorage();
  const userName = state.name;
  const drawerWidth = 300;

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const getDueDateColor = (dueDate: string) => {
    const today = new Date();
    const due = new Date(dueDate);
    return due < today ? 'error.main' : 'success.main';
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent<number>) => {
    onItemsPerPageChange(event.target.value as 3 | 6 | 9 | 12);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    const [path, direction] = event.target.value.split('-');
    onSortPathChange(path as 'scheduledDate' | 'dueDate');
    onSortDirectionChange(direction as 'asc' | 'desc');
  };

  const handleGradeChange = (event: SelectChangeEvent<number>) => {
    onGradeChange(event.target.value as number);
  };

  return (
    <Box sx={mainContainerStyles}>
      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={drawerStyles(isMobile, drawerWidth)}
      >
        <Box sx={drawerContentStyles(isMobile, drawerWidth)}>
          <Avatar src={profilePicture} sx={avatarStyles} />
          <Typography variant='h4'>{userName}</Typography>
          <Typography sx={welcomeMessageStyles}>
            Welcome back, young explorer! 🌟 Ready to embark on another exciting learning adventure?
          </Typography>
        </Box>
        <List sx={listStyles(drawerWidth)}>
          <ListItemButton href='/#/assessment/library' sx={listItemButtonStyles(true)}>
            <ListItemText primary='Assessments' />
          </ListItemButton>
        </List>
        <Box sx={{ marginTop: 'auto', padding: '16px' }}>
          <LogoutComponent />
        </Box>
        <Button onClick={toggleDrawer} sx={closeButtonStyles}>
          <CloseIcon />
        </Button>
      </Drawer>

      <Box sx={contentContainerStyles(isMobile, drawerOpen, drawerWidth)}>
        <AppBar position='static' sx={appBarStyles}>
          <Toolbar sx={toolbarStyles}>
            {isMobile && (
              <IconButton edge='start' color='inherit' sx={{ mr: 2 }} onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            {!isMobile && !drawerOpen && (
              <IconButton edge='start' color='inherit' sx={{ mr: 2 }} onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant='h6' sx={headerTypographyStyles}>
              Assessments
            </Typography>
            <Typography variant='h6' sx={{ mr: 2, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}>
              Hiya, {userName}!
            </Typography>
          </Toolbar>
        </AppBar>

        {isLoading ? (
          <Box sx={loadingErrorEmptyStyles}>
            <CircularProgress size={60} sx={{ color: '#46B8B0' }} />
          </Box>
        ) : hasError ? (
          <Box sx={loadingErrorEmptyStyles}>
            <ErrorOutlineIcon sx={{ color: 'error.main', fontSize: '60px' }} />
            <Typography sx={{ color: 'error.main', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }, ml: 2 }}>
              Something went wrong. Please try again later.
            </Typography>
            <Button variant='contained' onClick={onRetry} sx={assessmentButtonStyles} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : 'Retry'}
            </Button>
          </Box>
        ) : isEmpty ? (
          <Box sx={loadingErrorEmptyStyles}>
            <Typography sx={{ color: 'text.secondary', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
              No assessments available. Please check back later.
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Box sx={filterSortContainerStyles}>
                <Select
                  value={selectedGrade}
                  onChange={handleGradeChange}
                  variant='outlined'
                  size='small'
                  sx={selectStyles}
                >
                  <MenuItem value={3}>Grade 3</MenuItem>
                  <MenuItem value={4}>Grade 4</MenuItem>
                  <MenuItem value={5}>Grade 5</MenuItem>
                  <MenuItem value={6}>Grade 6</MenuItem>
                  <MenuItem value={7}>Grade 7</MenuItem>
                  <MenuItem value={8}>Grade 8</MenuItem>
                </Select>
                <Select
                  value={`${sortPath}-${sortDirection}`}
                  onChange={handleSortChange}
                  variant='outlined'
                  size='small'
                  sx={selectStyles}
                >
                  <MenuItem value='scheduledDate-asc'>Scheduled Date - Ascending</MenuItem>
                  <MenuItem value='scheduledDate-desc'>Scheduled Date - Descending</MenuItem>
                  <MenuItem value='dueDate-asc'>Due Date - Ascending</MenuItem>
                  <MenuItem value='dueDate-desc'>Due Date - Descending</MenuItem>
                </Select>
              </Box>

              <Box sx={assessmentHeaderStyles}>
                <Typography variant='h6' sx={{ color: '#46B8B0', fontWeight: 'bold' }}>
                  Hey Super Learner! 🚀 Ready to show off your awesome skills? Lets make learning fun together! ✨
                </Typography>
              </Box>

              <Box sx={gridContainerStyles}>
                <Grid container spacing={3}>
                  {assessments.map((assessment) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={assessment.id} sx={gridItemStyles}>
                      <Card
                        sx={{
                          ...cardStyles,
                          border: `2px solid ${getDueDateColor(assessment.dueDate as string)}`,
                          borderRadius: '16px',
                          position: 'relative',
                        }}
                      >
                        {assessment.isCompleted && (
                          <Box sx={completedOverlayStyles}>
                            <Typography variant='h6'>Completed</Typography>
                          </Box>
                        )}
                        <CardMedia
                          component='img'
                          sx={cardMediaStyles}
                          image={assessment.displayImage || '/path/to/default/image'}
                          alt={assessment.displayName}
                        />
                        <CardContent sx={cardContentStyles}>
                          <Box>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 1 }}>
                              {assessment.displayName}
                            </Typography>
                            {assessment.scheduledDate && (
                              <Typography variant='body2' sx={{ mb: 1 }}>
                                Scheduled Date: {new Date(assessment.scheduledDate).toLocaleDateString('en-GB')}
                              </Typography>
                            )}
                            {assessment.dueDate ? (
                              <Typography variant='body2' sx={{ mb: 1, color: getDueDateColor(assessment.dueDate) }}>
                                Due Date: {new Date(assessment.dueDate).toLocaleDateString('en-GB')}
                              </Typography>
                            ) : (
                              <Typography variant='body2' sx={{ mb: 1 }}>
                                Due Date: N/A
                              </Typography>
                            )}
                          </Box>
                          <Button
                            variant='contained'
                            fullWidth
                            sx={assessmentButtonStyles}
                            onClick={() =>
                              onSelect(assessment.id, Array.isArray(assessment.topicIds) ? assessment.topicIds : [])
                            }
                            disabled={assessment.isCompleted}
                          >
                            Take Assessment
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box sx={paginationContainerStyles}>
              <Typography sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)} ({totalItems} assessments)
              </Typography>
              <Pagination
                count={Math.ceil(totalItems / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={paginationStyles}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  variant='outlined'
                  size='small'
                  sx={selectStyles}
                >
                  {[3, 6, 9, 12].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AssessmentLibrary;
