import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AssessmentsLibrary from '../../components/assesments/AssessmentLibraryPage';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useLocalStorage } from '../../context/LocalStorageContext';
import { useAsyncEffect } from '../../hooks/use-async';
import { Box, CircularProgress, SxProps, Theme } from '@mui/material';

const AssessmentsLibraryContainer: React.FC = () => {
  const [assessments, setAssessments] = useState<any[]>([]);
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state } = useLocalStorage();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<3 | 6 | 9 | 12>(6);
  const [sortPath, setSortPath] = useState<'scheduledDate' | 'dueDate'>('dueDate');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [selectedGrade, setSelectedGrade] = useState<number>(3);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [completedAssessmentsFetched, setCompletedAssessmentsFetched] = useState<boolean>(false);
  const navigate = useNavigate();

  const loadingErrorEmptyStyles: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };

  const checkAssessmentCompletion = async (assessmentId: string): Promise<boolean> => {
    const { tenantId, userId } = state;
    if (!tenantId || !userId) {
      throw new Error('Missing tenantId or userId');
    }
    try {
      const response = await yellowOwlApi.findCompletedAssessment({
        tenantId,
        userId,
        assessmentIds: [assessmentId],
      });
      return response.data.some((assessment: any) => assessment.isCompleted);
    } catch (error) {
      console.error('Error checking assessment completion:', error);
      return false;
    }
  };

  const handleSelectAssessment = async (assessmentId: string, topicsIds: number[]) => {
    const isCompleted = await checkAssessmentCompletion(assessmentId);
    if (isCompleted) {
      alert('This assessment is already completed.');
      return;
    }
    navigate(`/assessment-questions/${assessmentId}`, { state: { topicsIds } });
  };

  const [assessmentsState] = useAsyncEffect<any>({
    fn: async () => {
      const { tenantId, userId } = state;
      if (!tenantId || !userId) {
        throw new Error('Missing tenantId or userId');
      }
      try {
        const result = await yellowOwlApi.getAllAssessments(
          tenantId!,
          userId!,
          selectedGrade,
          itemsPerPage,
          currentPage,
          sortPath,
          sortDirection
        );
        return result.data;
      } catch (error) {
        setHasError(true);
        console.error('Error fetching assessments:', error);
        return { page: 1, pageSize: 6, totalItems: 0, items: [] };
      }
    },
    dependencies: [
      state.tenantId,
      state.userId,
      selectedGrade,
      itemsPerPage,
      currentPage,
      sortPath,
      sortDirection,
      retryCount,
    ],
  });
  useEffect(() => {
    const fetchCompletedAssessments = async (assessments: any[]) => {
      const { tenantId, userId } = state;
      if (!tenantId || !userId) {
        throw new Error('Missing tenantId or userId');
      }
      try {
        const assessmentIds = assessments.map((assessment) => assessment.id);
        const response = await yellowOwlApi.findCompletedAssessment({
          tenantId,
          userId,
          assessmentIds,
        });
        const completedAssessments = response.data;
        return assessments.map((assessment) => ({
          ...assessment,
          isCompleted: completedAssessments.some(
            (completed: any) => completed.assessmentId === assessment.id && completed.isCompleted
          ),
        }));
      } catch (error) {
        console.error('Error fetching completed assessments:', error);
        return assessments;
      }
    };

    if (!state.tenantId || !state.userId) {
      setIsLoading(true);
      return;
    }
    // Update loading state based on assessment data
    if (assessmentsState.isLoading || !assessmentsState.result) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (assessmentsState.result && !completedAssessmentsFetched) {
        fetchCompletedAssessments(assessmentsState.result.items).then((updatedAssessments) => {
          setAssessments(updatedAssessments);
          setTotalItems(assessmentsState.result.totalItems);
          setIsEmpty(updatedAssessments.length === 0);
          setCompletedAssessmentsFetched(true);
        });
      }
      if (assessmentsState.error) {
        setHasError(true);
      }
    }
  }, [assessmentsState, completedAssessmentsFetched, state]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (pageSize: 3 | 6 | 9 | 12) => {
    setItemsPerPage(pageSize);
    setCurrentPage(1);
  };

  const handleSortPathChange = (newSortPath: 'scheduledDate' | 'dueDate') => {
    setSortPath(newSortPath);
  };

  const handleSortDirectionChange = (newSortDirection: 'asc' | 'desc') => {
    setSortDirection(newSortDirection);
  };

  const handleGradeChange = (newGrade: number) => {
    setSelectedGrade(newGrade);
    setCurrentPage(1);
  };

  const handleRetry = () => {
    setHasError(false);
    setIsLoading(true);
    setRetryCount((prev) => prev + 1);
  };

  // Show loader while data is loading or fetching
  if (isLoading) {
    return (
      <Box sx={loadingErrorEmptyStyles}>
        <CircularProgress sx={{ color: '#46B8B0' }} />
      </Box>
    );
  }

  return (
    <AssessmentsLibrary
      isLoading={isLoading}
      hasError={hasError}
      isEmpty={isEmpty}
      assessments={assessments}
      onSelect={handleSelectAssessment}
      currentPage={currentPage}
      itemsPerPage={itemsPerPage}
      totalItems={totalItems}
      onPageChange={handlePageChange}
      onItemsPerPageChange={handleItemsPerPageChange}
      sortPath={sortPath}
      sortDirection={sortDirection}
      onSortPathChange={handleSortPathChange}
      onSortDirectionChange={handleSortDirectionChange}
      selectedGrade={selectedGrade}
      onGradeChange={handleGradeChange}
      onRetry={handleRetry}
    />
  );
};

export default AssessmentsLibraryContainer;
