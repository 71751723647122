import React, { useState, useEffect, useContext } from 'react';
import { AssessmentQuestionResponse, AssessmentUserRequestBodyQuestionTypeEnum } from '@yellow-owl/client-sdk/src/api';
import AssessmentQuestion from '../../components/assesments/AssessmentQuestion';
import { useLocation } from 'react-router-dom';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { useParams } from 'react-router-dom';
import { AssessmentUserRequestBody } from '@yellow-owl/client-sdk';
import { useLocalStorage } from '../../context/LocalStorageContext';

type ShownQuestionItem = {
  questionCollectionId: number;
  questionId: number;
};

interface QuestionWithAnswer extends AssessmentQuestionResponse {
  answer?: string;
  audioUrl?: string;
  index: number;
  isEditable: boolean;
}

type LocalStorageData = {
  questionHistory: QuestionWithAnswer[];
  currentQuestionIndex: number;
  totalQuestions?: number; // Add this property
};

const AssessmentQuestionsContainer: React.FC = () => {
  const location = useLocation();
  const { state: locationState } = location;
  const { state } = useLocalStorage();
  const topicId = locationState?.topicsIds as number[];
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { assessmentId: assessmentIdString } = useParams<{ assessmentId: string }>();
  const assessmentId = assessmentIdString ? parseInt(assessmentIdString, 10) : undefined;
  const [rootTopicsIds] = useState<number[]>(topicId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [assessmentDone, setAssessmentDone] = useState<boolean>(false);
  const [shownQuestionIds, setShownQuestionIds] = useState<ShownQuestionItem[]>([]);
  const [audioRecorded, setAudioRecorded] = useState(false); // Add this state
  const [isAssessmentFinished, setIsAssessmentFinished] = useState<boolean>(false); // Add this state

  const [questionData, setQuestionData] = useState<QuestionWithAnswer>();
  const [questionHistory, setQuestionHistory] = useState<QuestionWithAnswer[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: string | null }>({});
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);

  const getAuthToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Auth token is missing');
    }
    return token;
  };

  const authToken = getAuthToken();

  const getCurrentQuestionIndex = (): number => {
    const storedData = JSON.parse(localStorage.getItem('assessmentData') || '{}') as LocalStorageData;
    return storedData.currentQuestionIndex ?? 0;
  };

  const updateCurrentQuestionIndex = (index: number) => {
    const storedData = JSON.parse(localStorage.getItem('assessmentData') || '{}') as LocalStorageData;
    const updatedData: LocalStorageData = {
      ...storedData,
      currentQuestionIndex: index,
    };
    localStorage.setItem('assessmentData', JSON.stringify(updatedData));
  };

  const fetchQuestions = async (updatedShownQuestionIds?: ShownQuestionItem[]) => {
    setIsLoading(true);
    try {
      if (questionData) {
        const response: AssessmentUserRequestBody = {
          tenantId: state.tenantId!,
          userId: state.userId!,
          assessmentId: assessmentId!.toString(),
          rootTopicId: rootTopicsIds[0].toString(),
          questionCollectionId: questionData.questionCollectionId.toString(),
          questionId: questionData.questionId.toString(),
          questionType: questionData.questionType as AssessmentUserRequestBodyQuestionTypeEnum,
          selectedAnswer: selectedOptions[questionData.questionId] || '',
          audioUrl: questionData.audioUrl ?? '',
        };
        await yellowOwlApi.saveAssessmentResponse(response);
      }

      const response = await yellowOwlApi.getQuestionForAssessment(
        {
          assessmentId: assessmentId!,
          rootTopicsIds: rootTopicsIds,
          shownQuestionIds: updatedShownQuestionIds || shownQuestionIds,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data: AssessmentQuestionResponse = response.data;

      const questionWithAnswer: QuestionWithAnswer = {
        ...data,
        answer: '',
        audioUrl: '',
        index: questionHistory.length,
        isEditable: true,
      };

      setQuestionHistory((prevHistory) => {
        const updatedHistory = [...prevHistory, questionWithAnswer];
        const updatedIds = [
          ...shownQuestionIds,
          {
            questionCollectionId: data.questionCollectionId,
            questionId: data.questionId,
          },
        ];
        setShownQuestionIds(updatedIds);
        return updatedHistory;
      });

      setQuestionData(questionWithAnswer);
      updateCurrentQuestionIndex(questionWithAnswer.index);

      const storedData = JSON.parse(localStorage.getItem('assessmentData') || '{}') as LocalStorageData;
      if (!storedData.totalQuestions) {
        const updatedData: LocalStorageData = {
          ...storedData,
          totalQuestions: data.TotalQuestions,
        };
        localStorage.setItem('assessmentData', JSON.stringify(updatedData));
      }
    } catch (error: any) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextQuestion = () => {
    const currentIndex = getCurrentQuestionIndex();
    if (currentIndex < questionHistory.length - 1) {
      const nextIndex = currentIndex + 1;
      updateCurrentQuestionIndex(nextIndex);
      setQuestionData(questionHistory[nextIndex]);
    } else if (currentIndex === questionHistory.length - 1 && !assessmentDone) {
      const updatedShownQuestionIds = [
        ...shownQuestionIds,
        {
          questionCollectionId: questionData!.questionCollectionId,
          questionId: questionData!.questionId,
        },
      ];
      fetchQuestions(updatedShownQuestionIds);
    }
    setAudioRecorded(false);
  };

  const handlePreviousQuestion = () => {
    const currentIndex = getCurrentQuestionIndex();
    if (currentIndex > 0) {
      const previousIndex = currentIndex - 1;
      updateCurrentQuestionIndex(previousIndex);
      setQuestionData(questionHistory[previousIndex]);
    }
  };

  const handleAnswer = (answer: string) => {
    if (questionData && questionData.isEditable) {
      const updatedQuestion: QuestionWithAnswer = { ...questionData, answer, isEditable: false };
      setQuestionData(updatedQuestion);
      setQuestionHistory((prevHistory) => {
        return prevHistory.map((q) => (q.questionId === questionData.questionId ? updatedQuestion : q));
      });
    }
  };

  const handleOptionChange = (questionId: number, option: string) => {
    if (questionData && questionData.isEditable) {
      setSelectedOptions((prev) => ({ ...prev, [questionId]: option }));
    }
  };

  const handleFinishAssessment = async () => {
    setIsAssessmentFinished(true);
    setAssessmentDone(true); // Set assessmentDone to true immediately
    try {
      if (questionData) {
        const response: AssessmentUserRequestBody = {
          tenantId: state.tenantId!,
          userId: state.userId!,
          assessmentId: assessmentId!.toString(),
          rootTopicId: rootTopicsIds[0].toString(),
          questionCollectionId: questionData.questionCollectionId.toString(),
          questionId: questionData.questionId.toString(),
          questionType: questionData.questionType as AssessmentUserRequestBodyQuestionTypeEnum,
          selectedAnswer: selectedOptions[questionData.questionId] || '',
          audioUrl: questionData.audioUrl ?? '',
        };
        await yellowOwlApi.saveAssessmentResponse(response);
      }

      // Make the API call to save assessment completion
      await yellowOwlApi.saveAssessmentCompletion({
        assessmentId: assessmentId!,
        userId: Number(state.userId!),
        tenantId: Number(state.tenantId!),
      });
    } catch (error: any) {
      console.error('Error finishing assessment:', error);
      setHasError(true);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('assessmentData') || '{}') as LocalStorageData;

    if (storedData.questionHistory && storedData.questionHistory.length > 0) {
      setQuestionHistory(storedData.questionHistory);

      const currentIndex = storedData.currentQuestionIndex ?? 0;
      setQuestionData(storedData.questionHistory[currentIndex]);
      updateCurrentQuestionIndex(currentIndex);
      const updatedShownQuestionIds = storedData.questionHistory.map((q) => ({
        questionCollectionId: q.questionCollectionId,
        questionId: q.questionId,
      }));
      setShownQuestionIds(updatedShownQuestionIds);
    } else {
      fetchQuestions();
    }
  }, []);

  useEffect(() => {
    const dataToStore: LocalStorageData = {
      questionHistory,
      currentQuestionIndex: getCurrentQuestionIndex(),
      totalQuestions: JSON.parse(localStorage.getItem('assessmentData') || '{}').totalQuestions, // Preserve totalQuestions
    };
    localStorage.setItem('assessmentData', JSON.stringify(dataToStore));
  }, [questionHistory, questionData]);

  useEffect(() => {
    if (questionData && questionHistory.length > 0) {
      const currentQuestion = questionHistory.find((q) => q.questionId === questionData.questionId);
      if (currentQuestion) {
        setSelectedOptions((prev) => ({
          ...prev,
          [questionData.questionId]: currentQuestion.answer || null,
        }));
      }
    }
  }, [questionData, questionHistory]);

  return (
    <AssessmentQuestion
      isLoading={isLoading}
      hasError={hasError}
      questionData={questionData}
      assessmentDone={assessmentDone}
      onNext={handleNextQuestion}
      onPrevious={handlePreviousQuestion}
      onAnswer={handleAnswer}
      hasPrevious={getCurrentQuestionIndex() > 0}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
      questionHistory={questionHistory}
      handleOptionChange={handleOptionChange}
      isEditable={questionData?.isEditable ?? false}
      audioRecorded={audioRecorded} // Pass audioRecorded to AssessmentQuestion
      setAudioRecorded={setAudioRecorded} // Pass setAudioRecorded to AssessmentQuestion
      isAssessmentFinished={isAssessmentFinished} // Pass isAssessmentFinished to AssessmentQuestion
      onFinishAssessment={handleFinishAssessment} // Pass handleFinishAssessment to AssessmentQuestion
      isLastQuestion={
        getCurrentQuestionIndex() ===
        ((JSON.parse(localStorage.getItem('assessmentData') || '{}') as LocalStorageData).totalQuestions ?? 0) - 1
      } // Pass isLastQuestion to AssessmentQuestion
    />
  );
};

export default AssessmentQuestionsContainer;
