/* eslint-disable */
import { useEffect, useState } from 'react';
import { Box, TextField, InputAdornment, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useLoginPageLogic } from '../../containers/signInAndSignUpContainer/signInAndSignUpContainer';
import { inputBoxStyle, buttonStyle } from './SignInAndRegisterPage';
import PhoneIcon from '@mui/icons-material/Phone';
import Layout from './Layout';
import { toast } from 'react-toastify';
import { yellowOwlMessages } from '../../Constants';

export const Registration: React.FC = () => {
  const [loginMode, setLoginMode] = useState<'phoneNumber' | 'email' | null>(null);
  const { selectedCountryCode, handleCountryCodeChange, setUserDetail, handleSignUp, isValidAge, isSignUpLoading } =
    useLoginPageLogic();
  const [name, setName] = useState('');
  const [dob, setDob] = useState<Dayjs | null>(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const storedLoginMode = localStorage.getItem('loginMode') as 'phoneNumber' | 'email' | null;
    setLoginMode(storedLoginMode);
  }, []);

  useEffect(() => {
    if (name && dob && ((loginMode === 'phoneNumber' && email) || (loginMode === 'email' && phoneNumber))) {
      setUserDetail({ name, dob: dob.toDate(), email, phoneNumber });
    }
    if (loginMode) {
      localStorage.setItem('loginMode', loginMode);
    }
  }, [name, dob, email, phoneNumber, loginMode, setUserDetail]);

  const handleRegister = () => {
    if (!name || name.length <= 3) {
      toast.error(yellowOwlMessages.NAME_REQUIRED);
      return;
    }

    if (!dob || !isValidAge(dob.toDate())) {
      toast.error(yellowOwlMessages.AGE_REQUIRED);
      return;
    }

    if (loginMode === 'phoneNumber' && !email) {
      toast.error(yellowOwlMessages.EMAIL_REQUIRED);
      return;
    }

    if (loginMode === 'email' && !phoneNumber) {
      toast.error(yellowOwlMessages.PHONE_NUMBER_REQUIRED);
      return;
    }
    handleSignUp();
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleRegister();
    }
  };

  return (
    <Layout heading='Registration' passwordResetSuccess={false} setPasswordResetSuccess={() => {}}>
      <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
        <TextField
          placeholder={yellowOwlMessages.ENTER_NAME}
          fullWidth
          sx={{ marginBottom: '10px' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={(e) => setName(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <DatePicker
          label={yellowOwlMessages.ENTER_DOB}
          sx={{ marginBottom: '10px', width: '100%' }}
          value={dob}
          onChange={(date: Dayjs | null) => {
            setDob(date);
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              onKeyPress: handleKeyPress,
            },
          }}
        />
        {loginMode === 'phoneNumber' && (
          <TextField
            placeholder={yellowOwlMessages.ENTER_EMAIL}
            fullWidth
            sx={{ marginBottom: '10px' }}
            value={email}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon sx={{ color: '#4682B4' }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        )}
        {loginMode === 'email' && (
          <Box sx={{ display: 'flex', gap: 1, marginBottom: '10px' }}>
            <Select
              value={selectedCountryCode}
              onChange={handleCountryCodeChange}
              sx={{ width: '30%', '& .MuiSelect-icon': { color: '#4682B4' } }}
            >
              <MenuItem value='+91'>+91 (India)</MenuItem>
              <MenuItem value='+1'>+1 (USA)</MenuItem>
              <MenuItem value='+44'>+44 (UK)</MenuItem>
            </Select>
            <TextField
              placeholder={yellowOwlMessages.ENTER_PHONE_NUMBER}
              fullWidth
              value={phoneNumber.replace(selectedCountryCode, '')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneIcon sx={{ color: '#4682B4' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setPhoneNumber(`${selectedCountryCode}${e.target.value}`)}
              onBlur={(e) => setPhoneNumber(`${selectedCountryCode}${e.target.value}`)}
              onKeyPress={handleKeyPress}
            />
          </Box>
        )}
        <Button variant='contained' onClick={handleRegister} sx={buttonStyle} disabled={isSignUpLoading}>
          {isSignUpLoading ? <CircularProgress size={24} /> : yellowOwlMessages.REGISTER}
        </Button>
      </Box>
    </Layout>
  );
};
