import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import {
  AGE_KEY,
  CATEGORY_ID_KEY,
  COUNTRY_CODE_KEY,
  DOB_KEY,
  EMAIL_KEY,
  LOGIN_MODE,
  PHONE_NUMBER_KEY,
  TENANT_ID_KEY,
  useLocalStorage,
  USER_ID_KEY,
  USER_NAME_KEY,
  USER_TYPE,
} from '../../context/LocalStorageContext';
import { EXPLORE_MAGAZINES, REGISTRATION } from '../router';

export const UserDetailGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { state, updateLocalStorage } = useLocalStorage();
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const checkUserDetails = async () => {
      const { userId, tenantId, categoryId, phoneNumber, parentEmail, loginMode, rollNumber, name, dateOfBirth } =
        state;

      if (!userId || !tenantId || !categoryId || (!phoneNumber && !parentEmail) || !name || !dateOfBirth) {
        let userDetailsResponse;
        if (loginMode === 'phoneNumber' && phoneNumber) {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(phoneNumber);
        } else if (loginMode === 'email' && parentEmail) {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(undefined, parentEmail);
        } else if (loginMode === 'rollNumber' && rollNumber) {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(undefined, undefined, rollNumber);
        }
        const userDetails = userDetailsResponse?.data;
        if (userDetails) {
          updateLocalStorage(LOGIN_MODE, userDetails.loginType ?? 'phoneNumber');
          updateLocalStorage(USER_ID_KEY, userDetails.userStrapiId ?? null);
          updateLocalStorage(USER_NAME_KEY, userDetails.userName);
          updateLocalStorage(DOB_KEY, userDetails.dateOfBirth ?? null);
          updateLocalStorage(EMAIL_KEY, userDetails.parentMailId);
          updateLocalStorage(AGE_KEY, userDetails.userAge.toString());
          updateLocalStorage(PHONE_NUMBER_KEY, userDetails.parentPhoneNumber ?? null);
          updateLocalStorage(COUNTRY_CODE_KEY, state.countryCode);
          updateLocalStorage(CATEGORY_ID_KEY, userDetails.commonCategoryId ?? null);
          updateLocalStorage(TENANT_ID_KEY, userDetails.tenantId ?? null);
          updateLocalStorage(USER_TYPE, userDetails.userType ?? null);
          localStorage.setItem('aiValidationFields', JSON.stringify(userDetails.aiValidationFields));
          if (!state.loginMode) {
            updateLocalStorage(LOGIN_MODE, loginMode!);
          }
          if (
            userDetails.tenantId === '' ||
            userDetails.commonCategoryId === '' ||
            userDetails.userName === '' ||
            userDetails.dateOfBirth === ''
          ) {
            navigate(REGISTRATION);
          }
        } else {
          navigate(REGISTRATION);
        }
      } else {
        if (!state.loginMode) {
          updateLocalStorage(LOGIN_MODE, loginMode!);
        }
        navigate(EXPLORE_MAGAZINES);
      }
      setChecked(true);
    };

    if (!checked) {
      checkUserDetails();
    }
  }, [navigate, checked]);

  return <>{children}</>;
};
